import { createTheme } from '@mui/material/styles';

import { paletteMui } from './palette';

export const theme = createTheme({
    palette: paletteMui,
    spacing: 2,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '27px'
                },
                containedPrimary: {
                    background:
                        'linear-gradient(232.2deg, #0262E9 0%, #0054AE 100%)'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '8px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    padding: '8px',
                    minHeight: '120px'
                }
            }
        }
    }
});
